//get length of letters after comma ,
export const getLengthAfterComma = (myval) =>{
    let sliced_ = myval.slice(myval.indexOf(",")+1);
    return sliced_.length
  }
  
  //get number of length
  export const getLength =(num) =>{
    let onlyDigits = num.replace('.',"").replace('.',"").replace('.',"")
    onlyDigits = onlyDigits.replace(',',"").replace(',',"").replace(',',"")
  
    return onlyDigits.length
  }
  
  //format currency
  // export const formatToTL =(amount) => {
  //   let TL = Intl.NumberFormat("tr-TR");
  
  //   let initialLenght = getLength(amount)
  //   //convert to string remove periods and replace comma with period as expected by the 
  //   //curreny formater (data should be in english format before passing to it)
  //   let amntStr = amount
  //   amntStr = amntStr.replace('.',"")
  //   amntStr = amntStr.replace(",",".")
  //   amntStr= parseFloat(amntStr)
    
  //   //after parse float the trailing zero (if any)AFTER PERIOD are lost so get them back by padding
  //   amntStr = amntStr.toPrecision(initialLenght)
  //   //now format the value currency wise and if no comma found in 
  //   //the result attach ",00" to it to signify actual turkish currency formating
  //   //",00" means two decimals at end
  //   amntStr = TL.format(amntStr)//toLocaleString("tr-TR")
  //   if (!amntStr.includes(",")) {
  //     amntStr = amntStr+",00"
  //   }else if(getLengthAfterComma(amntStr)<2){// if only one trailing zero included
  //     amntStr = amntStr+"0"
  
  //   }
  //   return amntStr;
  // }

  export const formatToTL =(amount,source='input') => {
    let TL = Intl.NumberFormat("tr-TR");
  
    let initialLenght = getLength(amount)
    //convert to string remove periods and replace comma with period as expected by the 
    //curreny formater (data should be in english format before passing to it)
    let amntStr = amount
    if (source!='database') {
      amntStr = amntStr.replace('.',"")
      amntStr = amntStr.replace(",",".")
    }
    amntStr= parseFloat(amntStr)
    
    //after parse float the trailing zero (if any)AFTER PERIOD are lost so get them back by padding
    amntStr = amntStr.toPrecision(initialLenght)
    //now format the value currency wise and if no comma found in 
    //the result attach ",00" to it to signify actual turkish currency formating
    //",00" means two decimals at end
    amntStr = TL.format(amntStr)//toLocaleString("tr-TR")
    if (!amntStr.includes(",")) {
      amntStr = amntStr+",00"
    }else if(getLengthAfterComma(amntStr)<2){// if only one trailing zero included
      amntStr = amntStr+"0"
  
    }
    return amntStr;
  }

  export const formatToNormal = (amount) => { 
    let onlyDigits = amount.replace('.',"").replace('.',"").replace('.',"")
    onlyDigits = onlyDigits.replace(',',"").replace(',',".");

    return parseFloat(onlyDigits);

   }

   // format datestring into the form accepted by js Date function
   export const  createDateObj =(dat_str,time_str) =>{
        //dat_str: "03-01-2024"
        //,time_str: 09:20
        let timeArr = time_str.split(":")

        // dat_str format should be dd/mm/yyyy. Separator can be anything e.g. / or -. It wont effect
        var dt1   = parseInt(dat_str.substring(0,2));
        var mon1  = parseInt(dat_str.substring(3,5));
        var yr1   = parseInt(dat_str.substring(6,10));
        var date1 = new Date(yr1, mon1-1, dt1);
        return date1;
        // console.log(yr1+'/'+mon1-1+'/'+dt1); //minus one is extremely necessary since new Date() is 0 indexed for months
        // return yr1+'/'+mon1-1+'/'+dt1
    }

    export const countdown = (target_timestamp,subtracted_milliseconds)=> {
      var now = new Date().getTime();
      // Calculate the difference in milliseconds between the target date and the current date
      var distance = target_timestamp - now; //subtracting 1 second from it (60 milli seconds) every minute
  
      // Calculate days, hours, minutes, and seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);
  
      // Return the countdown as an object
      // console.log(minutes);
      
      return {
          days:   days,
          hours:   hours,
          minutes: minutes,
          seconds: seconds
      };
  }