import { useEffect, useState } from "react";
import { EVENT_OWNER_DISAPPROVED_LIST, GET_USER_INSTAGRAM, approveAttendee, disapproveAttendee, generalGetMethod } from './api-calls/requests';
import { useLocation } from "react-router-dom";
import { formatToTL } from "./helpers";


const DisapprovedUserList = (props)=>{

    const [rejectedUsers, setrejectedUsers] = useState([]);

    var user_id = '';
    var user_type ='';
    var first_name ='';
    var last_name ='';

    if (JSON.parse(localStorage.getItem('user_id'))) {  
        user_id = JSON.parse(localStorage.getItem('user_id'))
        user_type = JSON.parse(localStorage.getItem('user_type'))
        first_name = JSON.parse(localStorage.getItem('first_name'))
        last_name = JSON.parse(localStorage.getItem('last_name') )
       }

  const [errorMessage, seterrorMessage] = useState('');


    //disapprove an attendees payment so that it will be returned to him
    function disApprove(user_id) {
        //clear previus error message if any
        seterrorMessage('')
        disapproveAttendee({"user_id":user_id,"event_id":props.event_id}).then(
            (response) => {
                if (!response.status) {
                    seterrorMessage("Başarsiz Redetme! Tekrar Deneyiniz")
                }else if (response.status===200) {
                    window.location.reload();
                }
            }
        )
    }

    //approve an attendees payment
     function approve(user_id) {
        //clear previus error message if any
        seterrorMessage('')
        approveAttendee({"user_id":user_id,"event_id":props.event_id}).then(
            (response) => {
                if (!response.status) {
                    seterrorMessage("Başarsiz Onaylama! Tekrar Deneyiniz")
                }else if (response.status===200) {
                    window.location.reload();
                }
            }
        )
    }

    //open user instagram page
    function showInstagram(user_id)
    {
        generalGetMethod(GET_USER_INSTAGRAM+user_id).then((response) => { 
            if (!response.status) {
                //handle errors
            }else if(response.status===200)
            {
                //redirect to a new window
                window.open("https://www.instagram.com/"+response.data.user_instagram.replace("@",""), "_blank")
            }
         })
    }

    useEffect(() => {
     generalGetMethod(EVENT_OWNER_DISAPPROVED_LIST+props.event_id).then(
        (response) => { 
            if (!response.status) {
                // handle error
            } else if(response.status===200){
                setrejectedUsers(response.data.disapproved_list);
            }
         }
     )
    }, [])
    

// console.log(props.index)
    return(
        <div className={"w-full"}>
            {rejectedUsers&&
            <div className="w-full">
                <table className="text-gray-600 w-full bg-white">
                    <thead className="bg-blue-200 pb-3">
                        <th>ID</th>
                        <th>Ad Soyad</th>
                        <th>Ödediği tutari</th>
                        <th>Eylemler</th>
                    </thead>

                    <tbody>
                        {rejectedUsers&&rejectedUsers.map((record) => (
                            <tr className="mb-[2rem] border-b-2 border">
                                <td className="py-2">{record.user_id}</td>
                                <td>{record.first_name+' '+record.last_name}</td>
                                <td>{formatToTL(record.total_amount,'database')}</td>
                                <td className="flex py-3 justify-center  space-x-2 w-[16rem]">
                                <p
                                    className=" cursor-pointer my-0 h-fit  bg-blue-300 w-fit px-1 rounded-md"
                                    onClick={() => { showInstagram(record.user_id) }}
                                    >instagram
                                </p>

                                {

                                }
                                <p
                                    className=" cursor-pointer my-0 h-fit bg-red-300 w-[4rem] rounded-md"
                                    onClick={() => { disApprove(record.user_id) }}
                                    >Reddet
                                </p>

                                <p
                                    className=" cursor-pointer my-0 h-fit bg-green-300 w-[4rem] rounded-md"
                                    onClick={() => { approve(record.user_id) }}
                                    >Onayla
                                </p>
                
                                </td>
                            </tr>
                         ))}
                    </tbody>
                </table>
            </div>
            }
            

        </div>
    );
}

export default DisapprovedUserList;