import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import '../App.css';
import axios from 'axios';
import { ALL_BILLINGS,  BILLING_STATUS,  DOWNLOAD_INVOICE, generalGetMethod, sendQrs } from './api-calls/requests';

// const ALL_EVENTS_URL = 'http://127.0.0.1:8000/api/all-billings';  //For dev
// const ALL_EVENTS_URL = 'https://api.buradago.com/public/api/all-billings';  //For p

const UploadedInvoices = ({ data }) => {

  const [billings, setBillings] = useState([]);
  const [trigerReloadBillings, settrigerReloadBillings] = useState(false)
const [status, setstatus] = useState("");
const getAllBillings =  ()=>{


    generalGetMethod(ALL_BILLINGS).then((response) => { 
      if (!response.status) {
        //handle errors
      }else if (response.status===200){
        if (response.data.billings.length>0) {
          setBillings(response.data.billings)
        }
      }
     }).catch()
}

const changeStatus = (bill_id,status) => { 
  generalGetMethod(BILLING_STATUS+status+'&billing_id='+bill_id).then((response) => { 
    if (!response.status) {
      //handle errors
    } else if(response.status===200) {
      //feedback user it is done

      // refresh page
      settrigerReloadBillings(!trigerReloadBillings)
    }
   }).catch()
 }

useEffect(
  ()=>{getAllBillings();},[trigerReloadBillings]
);

  return (
    <div className='mt-[5%]'>
      <h2 className='text-white'>Yüklenen Faturalar</h2>
    <Table striped bordered hover className='table'>
      <thead>
        <tr>
          <th>Etkinlik Kodu</th>
          <th>Etkinlik Adı</th>
          <th>Fatura Durumu</th>
          <th>Etkinlik Tarihi</th>
          <th>İndir</th>
          <th>Durumu Çevir</th>
        </tr>
      </thead>
      <tbody>
        {billings.map((item, index) => (
          <tr key={index}>
            <td>{item.event_id}</td>
            <td>{item.etkinlik_adi}</td>
            <td>{item.status}</td>
            <td>{item.etkinlik_tarihi}</td>
            <td>{
              <a className='text-xs'
               href={DOWNLOAD_INVOICE+item.owner_invoice_url}
              target='_blank'>{item.owner_invoice_url}</a>
            
            }</td>
            <td>
              <select
               className='bg-gray-500 rounded-5 cursor-pointer p-1 me-2 
                w-full '
                value={status}
                onChange={(event)=>{changeStatus(item.id,event.target.value)}}
              >
                <option value=''>Durum seç</option>
                <option value='onaylanmış'>Onaylanmış</option>
                <option value="ödenmiş">Ödenmiş</option>
                <option value="iptal edildi">İptal edildi</option>
                <option value="onaylanmamış">Onaylanmamış</option>
                
              </select>
             
              </td>
            
          </tr>
        ))}
        <tr><td></td> <td></td> <td></td> <td></td> <td></td><td></td>
        </tr>
        <tr><td></td> <td></td> <td></td> <td></td> <td></td> <td></td>
        </tr>
        <tr><td></td> <td></td> <td></td> <td></td> <td></td><td></td>
        </tr>
        <tr><td></td> <td></td> <td></td> <td></td> <td></td><td></td>
        </tr>
        <tr><td></td> <td></td> <td></td> <td></td> <td></td><td></td>
        </tr>
        <tr><td></td> <td></td> <td></td> <td></td> <td></td> <td></td>
        </tr>
        <tr><td></td> <td></td> <td></td> <td></td> <td></td><td></td>
        </tr>
        <tr><td></td> <td></td> <td></td> <td></td> <td></td><td></td>
        </tr>
        <tr><td></td> <td></td> <td></td> <td></td> <td></td><td></td>
        </tr>
        <tr><td></td> <td></td> <td></td> <td></td> <td></td> <td></td>
        </tr>
        <tr><td></td> <td></td> <td></td> <td></td> <td></td><td></td>
        </tr>
        <tr><td></td> <td></td> <td></td> <td></td> <td></td><td></td>
        </tr>
        
         
        
        
        {/* <tr><td>Tarkan ile Çay</td> <td>Saat: 12:00</td><td>Saat: 12:00</td></tr> */}
        {/* <tr><td>Tarkan ile Çay</td> <td>Saat: 12:00</td><td>Saat: 12:00</td></tr> */}

      </tbody>
    </Table>
    </div>
  );
};

export default UploadedInvoices;
