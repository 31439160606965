import React from "react"
import { CCol, CFooter, CLink, CRow } from "@coreui/react";

import myPdf from '../bg_basvuru_formu.pdf';

import '../App.css';
import { useNavigate } from "react-router-dom";

const MyFooter = () =>{ 

  const navigateThere = useNavigate();

  const handleLinkClick = (e) =>{
    // var url = e.href;
    // navigateThere('/footer-element-page',{state:{'id':e.target.id}});  ///ADD THE DATA TO BE SENT (THE ID OF THE CLICKED FOOTER ITEM)
    if(e.target.id=='kullanim'){
    navigateThere('/footer-element-page')
    }else if(e.target.id=='kvkk'){
      navigateThere('/footer-element-page1')

    } else if(e.target.id=='cerez'){
      navigateThere('/footer-element-page2')

    }else if(e.target.id=='iletisim'){
      navigateThere('/footer-element-page3')

    } 
  }

return (
    

    <CFooter style={{marginTop:'100px',marginBottom:'0px'}} >
  <div style={{fontSize:'13px'}}>
    {/* <CLink href="https://coreui.io"></CLink> */}
    <span> <strong> &copy;2023 Global Marks.</strong></span>
  </div>
  <div style={{fontSize:'13.5px'}}>
  <CRow className="align-items-center" >
    <CCol>
       {/* <a href={myPdf} className="footer-text" download> Başvuru Formu </a> */}
    
    </CCol>
    <CCol><a href="#" id="kullanim" className="footer-text" onClick={handleLinkClick}>Kullanım Koşulları</a></CCol> 
    <CCol><a href="#" id="kvkk" className="footer-text" onClick={handleLinkClick}>KVKK Aydınlatma Metni</a></CCol>
    <CCol><a href="#" id="cerez" className="footer-text" onClick={handleLinkClick}>Çerez Politikası</a></CCol>
    {/* <CCol><a href="#" id="iletisim" className="footer-text" onClick={handleLinkClick}>İletişim</a></CCol> */}
  </CRow>
  </div>
  <div>
    {/* <span>Powered by</span>
    <CLink href="https://coreui.io">CoreUI</CLink> */}
  </div>
</CFooter>


);
}

export default MyFooter