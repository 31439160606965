import React from 'react'

const OrganizationalAddress = (props) => {
  return (
    <div className='bg-gray-400 mx-2 tex  rounded-md'>
        <div className='flex items-center justify-center'>
        <p className='text-gray-700 underline'
        >
            {props.address.address_name}  
        </p>
      </div>
      <div className='text-start pl-[1rem]'>
            <p>{props.address.organisation_name}</p> {/**address owner full name */}
            <p>{props.address.phone}</p> {/** phone*/}
            <p>{props.address.district+'/'+props.address.province}</p> {/**district/province */}
            <p className='text-gray-600'>{props.address.address}</p> {/** street address*/}
            <p className=''>{props.address.country}</p> {/** country*/}
        </div>
        {/* <div className='text-start pl-[1rem]'>
            <p>Rashid Mukaila</p>
            <p>548736234478</p>
            <p>Gungorein/Istanbul</p>
            <p><span>VKN: </span>637326</p>
            <p><span>Vergi Dairesi: </span>Kagithane</p>
            <p className='text-gray-600'>Koc Cad. Mevlana Mah. Idare Sk. No. 3/6 </p>
            <p>Türkiye</p>
        </div> */}
    </div>
  )
}

export default OrganizationalAddress