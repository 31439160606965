import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import axios from "axios";

import { useRef, useState } from 'react';
import { withRouter, useNavigate } from "react-router-dom";
import { FormLabel } from 'react-bootstrap';
import { addEventOwner } from './api-calls/requests';



const USER_REGEX = /^[A-z][A-z0-9-_]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
// const REGISTER_URL_PROD = 'http://127.0.0.1:8000/api/register';  //For dev
const REGISTER_URL_PROD = 'https://api.buradago.com/public/api/register';  //For production




function AddEventOwner() {
  const navigateThere = useNavigate()

  const userRef = useRef();
  const errRef = useRef();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [nick, setNick] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [instagram, setInstagram] = useState('');
  const [pwd, setPwd] = useState('');
  const [acc_name, setacc_name] = useState('');
  const [acc_number, setacc_number] = useState('');

  const [address, setAddress] = useState('');
  const [uploadedImage, setUploadedImage] = useState('')
  const [displayImage, setDisplayImage] = useState(null)

  const [validName, setValidName] = useState(false);
  const [userFocus, setUserFocus] = useState(false);

  
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);

  const [matchPwd, setMatchPwd] = useState('');
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);

  const [errMsg, setErrMsg] = useState('');
  const [success, setSuccess] = useState(false);

  


  const handleSubmit  = async (e) => {
    e.preventDefault();
    
    // if button enabled with JS hack
    // const v1 = USER_REGEX.test(name);
    // const v2 = PWD_REGEX.test(pwd);
    // if (!v1 || !v2) {
    //   console.log('There was an error');
    //     setErrMsg("Invalid Entry");
    //     return;
    // }

    const formData = new FormData();
    formData.append('firstName',firstName);
    formData.append('lastName',lastName);
    formData.append('nick',nick);
    formData.append('email',email);
    formData.append('phone',phone);
    formData.append('instagram',instagram);
    formData.append('password',pwd);
    formData.append('bank_account_name',acc_name)
    formData.append('bank_account_number',acc_number)
    // formData.append('file',uploadedImage);
    
     addEventOwner(formData).then(
      (response) => { 
        if (!response.status) {
          
          setErrMsg('Error adding event owner'); //CHANGE THIS TO RETURNED ERROR MESSAGE
        } else if(response.status===200){
          alert("Etkinlik kişi başaryle eklendi!");
          setSuccess(true);
          setLastName('');
          setPwd('');
          setMatchPwd('');
          navigateThere('/')
        }
       }
     ).catch((err) => { 
      setErrMsg('Error adding event owner'); //CHANGE THIS TO RETURNED ERROR MESSAGE
      });
     
    
  };

  
  return (
    <div className='mt-5 w-50 container'>
      <h3 style={{color:'white'}}>Etkinlik Kişisi Ekleme</h3>
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3"  controlId="formBasicEmail">
        {/* <Form.Label>Email address</Form.Label> */}
        <Form.Control type="text" name='firstName' placeholder="İsim" required
        value={firstName}
         onChange={(e) => setFirstName(e.target.value)}/>
        {/* <Form.Text className="text-muted">
          We'll never share your email with anyone else.
        </Form.Text> */}
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Control type="text" name='lastName' placeholder="Soyisim" required 
        value={lastName}        
        onChange={(e) => setLastName(e.target.value)}/>
       
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Control type="text" name='nick' placeholder="Nick/kullanıcı adı"
        value={nick}
         onChange={(e) => setNick(e.target.value)} />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicEma">
      <Form.Control type="email" name='email' placeholder="Mail" required
      value={email}
      onChange={(e) => setEmail(e.target.value)}/>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicEma">
      <Form.Control type="password" name='password' placeholder="Şifre" required 
      value={pwd}
      onChange={(e) => setPwd(e.target.value)} />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicPassword"> 
      {/* pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" */}
      <Form.Control type="tel" name="phone"  placeholder="Tel No." required
      value={phone}
       onChange={(e) => setPhone(e.target.value)} />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicEma">
      <Form.Control type="text" name='instagram' placeholder="İnstagram: @example12" required
      value={instagram}
      onChange={(e) => setInstagram(e.target.value)} />
      </Form.Group>

      {/* bank account name */}
      <Form.Group className="mb-3" controlId="formBasicEma">
      <Form.Control type="text" name='bank_account_name' placeholder="Banka Hesab Adı" required
      value={acc_name}
      onChange={(e) => setacc_name(e.target.value)} />
      </Form.Group>

      {/* bank acc. iban/number */}
      <Form.Group className="mb-3" controlId="formBasicEma">
      <Form.Control type="text" name='bank_account_number' placeholder="TR08938XXXXXXX" required
      value={acc_number}
      onChange={(e) => setacc_number(e.target.value)} />
      </Form.Group>

      {/* <Form.Group className="mb-3">
      <Form.Control type="file" name='profile_pic' 
      className='form-control-file'
      // value={uploadedImage}
      onChange={(e) => {setUploadedImage(e.target.files[0]);                        
        setDisplayImage(URL.createObjectURL(e.target.files[0]))}} />
      </Form.Group>
      <div className='mb-3 w-25'>
        <img src={displayImage}
         className='w-auto' alt='event owner profile'>
        </img>
      </div> */}


      <Button variant="primary" type="submit">
        Gönder
      </Button>
    </Form>
    </div>
  );
}

export default AddEventOwner;