
import {DayPicker} from "react-day-picker";
// import "react-day-picker/dist/style.css";
import { tr } from 'date-fns/locale';
import { format } from 'date-fns';

const DateSelector = ({selected,setSelected})=>{


    return(
        <div style={{color:'white'}}>
        <DayPicker
        locale={tr}
          mode='single'
          selected={selected}
        //   onSelect={setSelected}
          // onSelect={(selected)=>{setSelected(seliected); console.log(selected)}}
          onSelect={(selected)=>{setSelected(format(selected,'dd-MM-y'));}}
          />

          </div>
        

    );
}

export default DateSelector;