import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import axios from "axios";
import { tr } from 'date-fns/locale';


import { useEffect, useRef, useState } from 'react';
import { withRouter, useNavigate } from "react-router-dom";
import DateSelector from './date-selector';
import { format } from 'date-fns';
import HoursSelector from './hours-select-c';
import CitiesJson from '../cities';
import {addEvent, getCategoriesAndFenomens} from './api-calls/requests'

import { ADD_ETKINLIK_URL } from './api-calls/requests';

// import { DatePickerInput } from "rc-datepicker";
// import "rc-datepicker/lib/style.css";
const cities = Object.keys(CitiesJson);


const USER_REGEX = /^[A-z][A-z0-9-_]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
// const ADD_ETKINLIK_URL = 'http://127.0.0.1:8000/api/add-event';  //For dev
// const ADD_ETKINLIK_URL = 'https://api.buradago.com/public/api/add-event';  //For production




function EtkinlikOlusturForm() {
  // const { cities, city, setCity, districts, district, setDistrict } = useTurkeyCities();
  const [il, setIl] = useState('');
  const [ilce, setIlce] = useState('');
  const [relatedDistricts, setRelatedDistricts] = useState([])

  const [fenomenList, setFenomenList] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);

  const [selected, setSelected] = useState('Tarihi seç (00-00-0000)');
  const navigateThere = useNavigate()

  const userRef = useRef();
  const errRef = useRef();

  const [fenomenId, setFenomenId] = useState('');
  const [kategori_id, setKategori_id] = useState('');
  const [katilimSayisi, setKatilimSayisi] = useState('');
  const [etkinlikAdi, setEtkinlikAdi] = useState('');
  const [tarihi, setTarihi] = useState('(gün-ay-yil)');
  const [saat, setSaat] = useState('');
  const [mekan, setMekan] = useState('');
  const [venueFieldTitle, setvenueFieldTitle] = useState('Mekan');
  const [whenToSendTickets, setwhenToSendTickets] = useState('')
  // const [satisBaslangic, setSatisBaslangic] = useState('');
  const [satisBaslangicTarihi, setSatisBaslangicTarihi] = useState('(gün-ay-yil)');
  const [s_basaat, setSbasaat] =useState();
  const [satisBitisTarihi, setSatisBitisTarihi] = useState('(gün-ay-yil)');
  const [s_bisaat, setSbisaat] = useState();
  // const [il, setIl] = useState('');
  // const [ilce, setIlce] = useState('');
  const [sekil, setSekil] = useState('');
  const [katilimFiyati, setKatilimFiyati] = useState('');
  const [baslangicFiyat, setBaslangicFiyat] = useState('');
  const [minimumArtirmaFiyat, setMinimumArtirmaFiyat] = useState('');
  const [onlyVerifiedAccount, setonlyVerifiedAccount] = useState(false);

  const [uploadedImage, setUploadedImage] = useState(null);
  const [displayImage, setDisplayImage] = useState(null);
  const [eventRules, seteventRules] = useState('');
  const [eventContract, seteventContract] = useState(null);
  const [errMsg, setErrMsg] = useState('');

  
  const onChange = (jsDate, dateString) => {
    // ...
  };

  useEffect(
    ()=>{if(sekil==="artirmali"){
      setKatilimFiyati("")
    }else{
      setMinimumArtirmaFiyat("")
      setBaslangicFiyat("")
    }
  
  },[sekil]
  );

  //Fetcht data to fill the form select fields
  useEffect(() => {
		async function fetchInitialData() {
			const response = await getCategoriesAndFenomens();
			if(response.errors){

        console.log('Error occured filling select')}else if(response.fenomens) {
        setFenomenList(response.fenomens)
        // console.log(response.fenomens)
        setCategoriesList(response.categories)}
       
		}

		fetchInitialData();
	}, []);

  // Function to clear all the fields
  const clearFields = () => {
    setFenomenId('');
    setKategori_id('');
    setKatilimSayisi('');
    setEtkinlikAdi('');
    setTarihi('(gün-ay-yil)');
    setSaat('');
    setMekan('');
    // setSatisBaslangic('');
    setSatisBaslangicTarihi('(gün-ay-yil)');
    setSatisBitisTarihi('(gün-ay-yil)');
    // setIl('');
    // setIlce('');
    setSekil('');
    setKatilimFiyati('');
    setBaslangicFiyat('');
    setMinimumArtirmaFiyat('');
  };

  const submitRequest  = async () => {
  const payloadItems = JSON.stringify({
                  fenomenId,kategori_id,katilimSayisi,etkinlikAdi,tarihi,
                  saat,mekan,satisBaslangicTarihi,satisBitisTarihi,il,ilce,sekil,
                  katilimFiyati, baslangicFiyat, minimumArtirmaFiyat,s_basaat,s_bisaat,
                  onlyVerifiedAccount,eventRules,venueFieldTitle,whenToSendTickets
                 });

    //create a formData as payload and append all form values
    const formData = new FormData();
    const parsedPayload = JSON.parse(payloadItems)
    for (const key in parsedPayload){
      formData.append(key,parsedPayload[key])
      // console.log(payloadItems.key)
    }

    //append file
    formData.append('file',uploadedImage)
    formData.append('contract_doc',eventContract)
    // console.log('formData', formData)

    addEvent(formData).then((response) => { 
      if (!response.status) {
      setErrMsg('Error adding event'); //CHANGE THIS TO RETURNED ERROR MESSAGE
        
      } else if(response.status===200){
        alert("Etkinlik oluşturma başariyle tamamlandi!");
        navigateThere("/")
      }else{
        alert("Başarısız etkinlik oluşturma");
      }
     });
  };

  const handleSubmit = (event)=>{
    event.preventDefault(); 
    submitRequest();
  }


  return (
    <div>
      
    <Form onSubmit={handleSubmit} action='#'>
    <h5 style={{color:'white'}}>Etkinlik Oluştur</h5>
    <div className='row'>
      <div className='row'>
      <div className='col-sm-6'>
          <Form.Group className="mb-3">
            {/* <Form.Label>Email address</Form.Label> */}
            <Form.Select
            value={fenomenId}
            onChange={(event)=>{setFenomenId(event.target.value)}}
             aria-label="Default select example">
              <option value="">Fenomeni seç</option>
                {fenomenList.map(fenomen=>(
                <option key={fenomen.id} value={fenomen.id}>
                  {fenomen.first_name+" "+fenomen.last_name}
                </option>))}



              {/* <option value=''>Fenomen Seçimi</option>
              <option value="1">Olcay</option>
              <option value="2">Fatih</option>       */}
            </Form.Select>
            {/* <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text> */}      
          </Form.Group>
        </div>

          <div className='col-sm-6'>
            <Form.Group className="mb-3">
              <Form.Select
              value={kategori_id}
              onChange={(event)=>{setKategori_id(event.target.value)}}              
              >
                <option value="">Etkinlik Kategori Seç</option>
              {categoriesList.map(category=>(
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>))}

              </Form.Select>
            </Form.Group>
          </div>
      </div>

      <div className='row'>
          <Form.Group className="mb-3">
          <Form.Control type="number" name='katilim-sayisi'
           placeholder="Katılım Sayısı" required
           value={katilimSayisi}
           onInput={(event)=>{setKatilimSayisi(event.target.value)}}
          />
          </Form.Group>
      </div>

      <div className='row'>
          <Form.Group className="mb-3" >
          <textarea style={{backgroundColor:'white',width:'100%'}}
           placeholder="Etkinlik Adı" required
           value={etkinlikAdi}
           onInput={(event)=>{setEtkinlikAdi(event.target.value)}}
           >
          </textarea>       
          </Form.Group>
      </div>


      <div className='row '>          
          <div className='col-sm-6'>
            <Form.Group className="mb-3 d-flex" >
              <Form.Label style={{color:'white'}}
              className='mx-4'
              >Etkinlik Tarihi</Form.Label>
              <Form.Control type="text" 
              className='w-50'
              name=''
              placeholder="Tarihi" required readOnly 
                // format(tarihi,'P',{locale:tr})
                value={tarihi} onClick={()=>{setTarihi('')}}
                />
                </Form.Group>
                {(!tarihi &&<DateSelector  selected={tarihi} setSelected={setTarihi}/>)}
              
            {/* </Form.Group> */}
          </div>
          
          <div className='col-sm-3'>
            <Form.Group className="mb-3 d-flex" >
            <Form.Label className='me-3'
             style={{color:'white'}}> Saat</Form.Label>            
              <HoursSelector hour={saat} setHour={setSaat}/>
            </Form.Group>
          </div>

          <div className='col-sm-3 h-fit text-white'>
            <span htmlFor="">Doğrulamalı satış</span>
            <input
            onChange={() => {
              setonlyVerifiedAccount(!onlyVerifiedAccount) }}
             type="checkbox" />
          </div>          
      </div>
      
      {/* mekan */}
      <div className='row'>
          <Form.Group className="mb-3">
          <Form.Control type="text" name='mekan'
           placeholder="Etkinlik Mekanı (öğrn: American kafe)" required
          value={mekan}
          onChange={(e) => setMekan(e.target.value)} />
          </Form.Group>
      </div>

      {/* mekan basligi*/}
      <div className='row'>
          <Form.Group className="mb-3">
          <Form.Control type="text" name='mekan'
           placeholder="Mekan Başliği (öğrn: Buluşma yeri)" 
          value={venueFieldTitle}
          onChange={(e) => setvenueFieldTitle(e.target.value)} />
          </Form.Group>
      </div>

      <div className='row '>          
          <div className='col-sm-6'>
            <Form.Group className="mb-3" >
              <Form.Label style={{color:'white'}}>S. Başlangiç</Form.Label>
              <Form.Control type="text" name='' placeholder="Tarihi" required readOnly
                value={satisBaslangicTarihi} onClick={()=>{setSatisBaslangicTarihi('')}}
                />
                {(!satisBaslangicTarihi &&<DateSelector  selected={satisBaslangicTarihi} setSelected={setSatisBaslangicTarihi}/>)}
              
            </Form.Group>

            {/* S. Bas saati */}
            <Form.Group className="mb-3 d-flex" >
            <Form.Label style={{color:'white'}} className='me-3'
            > S. Başlangiç Saat</Form.Label>
            <HoursSelector hour={s_basaat} setHour={setSbasaat}/>
            </Form.Group>
            
          </div>
          
          <div className='col-sm-6'>
            <Form.Group className="mb-3" >
            <Form.Label style={{color:'white'}}>S. Bitiş</Form.Label>
              <Form.Control type="text" name='' placeholder="Tarihi" required readOnly
                value={satisBitisTarihi} onClick={()=>{setSatisBitisTarihi('')}}
                />
                {(!satisBitisTarihi &&<DateSelector  selected={satisBitisTarihi} setSelected={setSatisBitisTarihi}/>)}
              
            </Form.Group>

            {/* S Bit saati */}
            <Form.Group className="mb-3 d-flex" >
            <Form.Label style={{color:'white'}} className='me-3'
            >S. Bitiş Saat</Form.Label>            
              <HoursSelector hour={s_bisaat} setHour={setSbisaat}/>
            </Form.Group>
          </div>          
      </div>


      <div className='row '>          
          {/* When to send ticket emails */}
          <Form.Group className="mb-3">
          {/* <Form.Label style={{color:'white'}} className=' text-start me-3'> */}
          <label className='text-white text-start w-full'
          htmlFor="">
              Bilet Maili Gönderme Zamani - Etkinlik saatine:
          </label>
          {/* </Form.Label>  */}
          <Form.Select
                value={whenToSendTickets}
                required
                onChange={(event)=>{setwhenToSendTickets(event.target.value)}}
                aria-label="Default select example">
                  <option value="">Seç</option>
                  <option  value="0"> Anında -Bilet Satın Alındığında</option>
                  <option  value="1">Etkinlik saatine 3 Saat Önce</option>
                  <option  value="2">Etkinlik saatine 6 Saat Önce</option>
                  <option  value="3">Etkinlik saatine 1 Gün Önce</option>
          </Form.Select>

          </Form.Group>
          
          <div className='col-sm-6'>
            <Form.Group className="mb-3">
              <Form.Select
              value={il}
              onChange={(event)=>{
                setIl(event.target.value);
                setRelatedDistricts(CitiesJson[event.target.value]['districts'])}} >
              <option value=''>İl</option>   
              {cities.map(city => (
            <option key={`city-${city}`} value={city}>
              {city}
            </option>))}      
              </Form.Select>
            </Form.Group>
          </div>
          
          <div className='col-sm-6'>
              <Form.Group className="mb-3">
                <Form.Select
                value={ilce}
                onChange={(event)=>{setIlce(event.target.value)}}
                 >
                <option>İlçe</option>   
                {relatedDistricts.map(district => (
            <option key={`district-${district}`} value={district}>
              {district}
            </option>))}        
                </Form.Select>
              </Form.Group>
          </div>          
      </div>

      
      
      <div className='row'>
        <div className='col-sm-6'>
          <Form.Group className="mb-3">
            <Form.Select
            value={sekil}
            onChange={(event)=>{setSekil(event.target.value)}}  
            required          
            >
               <option value=''>Etkinlik Turu Seç</option>
              <option value='sabit'>Sabit</option>  
              <option value='artirmali'>Artirmali</option>  
                   
            </Form.Select>
          </Form.Group>
        </div>
        

        <div className='col-sm-6'>
          <Form.Group className="mb-3">
          <Form.Control 
          value={katilimFiyati}
          onInput={(event)=>{setKatilimFiyati(event.target.value)}}
          type="text" name='sabit-fiyat' placeholder="Katılım Fiyatı" required
            hidden={(sekil==="artirmali")?true:null}
          />
          </Form.Group>
        </div>
      </div>

      <div className='row'>
        <div className='col-sm-6'>
          <Form.Group className="mb-3">
          <Form.Control 
          value={baslangicFiyat}
          onInput={(event)=>{setBaslangicFiyat(event.target.value)}}
          type="text" name='baslangic-fiyat' placeholder="Başlangiç Fiyat" required
          hidden={(sekil==="artirmali")?null:true}
          />
          </Form.Group>
        </div>
        

        <div className='col-sm-6'>
          <Form.Group className="mb-3">
          <Form.Control 
          value={minimumArtirmaFiyat}
          onInput={(event)=>{setMinimumArtirmaFiyat(event.target.value)}}
          type="text"  placeholder="Minimum Artırma Tutarı" required
          hidden={(sekil==="artirmali")?null:true}
          />
          </Form.Group>
        </div>
      </div>

      {/* etkinlik kurallari */}
      <div className='row'>
        <label htmlFor=""
        className='text-white text-start'
        >
          Etkinlik kurallari
        </label>
          <Form.Group className="mb-3" >
          <textarea style={{backgroundColor:'white',width:'100%'}}
          required
           placeholder="Etkinlik Kuralları" 
           value={eventRules}
           onInput={(event)=>{seteventRules(event.target.value)}}
           >
          </textarea>       
          </Form.Group>
      </div>

      {/* etkinlik sozlesmesi */}
      <Form.Group className="mb-3">
      {/* <Form.Label style={{color:'white'}} className=' text-start me-3'> */}
      <label className='text-white text-start w-full'
      htmlFor="">
          Etkinlik Sözleşmesi
      </label>
      {/* </Form.Label>  */}
      <Form.Control type="file" name='profile_pic'  
      required
      id='etkinlik_contract'
      className='form-control-file'
      // value={eventContract}
      onChange={(e) => {seteventContract(e.target.files[0]);                        
      }} />
      </Form.Group>

      {/*event image addition  */}
      <Form.Group className="mb-3">
      <label className='text-white text-start w-full'
      htmlFor="">
          Etkinlik Fotosu
      </label>
      <Form.Control type="file" name='profile_pic'  
      id='etkinlik_image'
      className='form-control-file'
      // value={uploadedImage}
      onChange={(e) => {setUploadedImage(e.target.files[0]);                        
        setDisplayImage(URL.createObjectURL(e.target.files[0]))}} />
      </Form.Group>
      <div className='mb-3 w-25'>
        <img style={{maxHeight:'365px',maxWidth:'20rem'}} src={displayImage}
         className='w-auto' alt='event owner profile'>
        </img>
      </div>


      </div>
      {/* <Form.Group className="mb-3"> */}
        <Button  type="submit" 
        onClick={handleSubmit}
     
        variant="primary"
          >
          Oluştur
        </Button> 
      {/* </Form.Group> */}
    </Form>
    </div>
  );
}

export default EtkinlikOlusturForm;