import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { generalPostNotFormMethod, GET_EVENT_ATTENDEES } from "./api-calls/requests";
import ViewBillingAddress from "./user_billing/view-billingAddress";



const EventAttendees = () => {
    const navigateThere = useNavigate()
    const [events, setevents] = useState([]);
    const [attendants, setattendants] = useState( [ ]);
    const [errorMessage, seterrorMessage] = useState();
    const [toggleLoadAttendees, settoggleLoadAttendees] = useState(true);
    const [showBillingPopup, setshowBillingPopup] = useState(false)
    const [billAddId, setbillAddId] = useState('');
    const location = useLocation();
    const eventInfo = location.state || {"event_id":-1033};

    //attendee statuses
//    const onayComp = <p className="bg-lime-300 text-xs ">Onaylanmış</p>
//    const toOnayComp = <p className="bg-yellow-300 text-xs ">Onaylanmamış</p>
//    const redComp = <p className="bg-red-500 text-xs ">Rededilmış</p>

    const displayBillingInfo = (billingAdd) => {
        setbillAddId(billingAdd);
        setshowBillingPopup(true);
    }
  
    useEffect(() => {
        // 
        generalPostNotFormMethod(GET_EVENT_ATTENDEES,{"event_id":eventInfo.event_id}).then(
            (response) => {
                if (!response.status) {
                    seterrorMessage("katılımcı listesi alınamadı")
                } else if (response.status===200) {
                    setattendants(response.data.attendees)
                }
            }
        )
    
    }, [toggleLoadAttendees]);

    
    return(
        <div className="px-4 mt-20">
            
            <div className="w-full overflow-y-auto h-96 items-start
             flex mt-8 border rounded-md">
                {/* popup for billing address */}
                {showBillingPopup&&
                    <ViewBillingAddress
                    addressId={billAddId}
                    setshowBillingPopup={setshowBillingPopup}
                    />
                }
                <table className="w-full text-sm border-3 bg-gray-300
                 ">
                    <thead className="w-full space-x-3 text-xs">
                        <tr>
                            <th>ID</th>
                            <th>Ad Soyad</th>
                            <th>Instagram</th>
                            <th>Tutar</th>
                            <th>Eylemler</th>
                        </tr>

                        {/* <th></th> */}
                    </thead>

                    <tbody className="text-xs text-black">
                        {attendants&&
                            attendants.map((attndnt, index) => (
                                <tr key={index}className="bg-white">
                                    <td className="pb-4">{attndnt.user_id}</td>
                                    <td className="pb-4">{attndnt.first_name +" "+attndnt.last_name}</td>
                                    <td>
                                        {/* users instagram name with at sign */}
                                       <a target="_blank"
                                       href= {"https://www.instagram.com/"+attndnt.instagram}
                                       content="link to user instagram">
                                        
                                        {attndnt.instagram}
                                       </a>
                                    </td>
                                    <td className="pb-4">
                                    </td>

                                    <td className="">
                                       <button
                                       className="bg-blue-500 text-white px-2 py-1 rounded-md"
                                       onClick={() => displayBillingInfo(attndnt.billing_address_id)}
                                       >
                                       Fatura bilgiler
                                       </button>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>

                </table>
            </div>
        </div>
    )
}
export default EventAttendees;