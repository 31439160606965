import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import '../App.css';
import axios from 'axios';
import { PAYMENTS_TO_REVERSE, generalGetMethod } from './api-calls/requests';
import { useLocation } from 'react-router-dom';


const ToreverseList = () => {

  const location = useLocation()

  const event_info = location.state || null;

  const [reversal_list, setreversal_list] = useState([]);

const getList = ()=>{

    const response = generalGetMethod(PAYMENTS_TO_REVERSE+"?event_id="+event_info.event_id).then(
      (response) => { 
        console.log('response',response);
        if (!response.status) {
          //handle error
        }else if (response?.status===200){
          if (response.data.reversal_list.length>0) {
            setreversal_list(response?.data.reversal_list)
          }
        }

       }
    ).catch();
}


useEffect(() => { 
  
  getList()
 },[]
);


  return (
    <div className="mt-[3rem] ">
      <Table striped bordered hover className=' table'>
        <thead>
          <tr>
            <th>Kullanici ID</th>
            <th>Ad</th>
            <th>Soyad</th>
            <th>Mail Addressi</th>
            <th>Ödeme Miktari</th>
            <th className='bg-red-300'>Eylemler</th>
          </tr>
        </thead>
        <tbody>
          {reversal_list.map((item, index) => (
            <tr key={index}>
              <td className=''>{item.user_id}</td>
              <td>{item.first_name}</td>
              <td>{item.last_name}</td>
              <td>{item.email}</td>
              <td>{item.total_amount}</td>
              <td>
                <span className="rounded-md cursor-pointer px-3 bg-red-400">
                  Iade Et
                </span>
               </td>
                
            </tr>
          ))}
          <tr><td></td> <td></td> <td></td> <td></td> <td></td><td></td>
          </tr>
          <tr><td></td> <td></td> <td></td> <td></td> <td></td> <td></td>
          </tr>
          <tr><td></td> <td></td> <td></td> <td></td> <td></td><td></td>
          </tr>
          <tr><td></td> <td></td> <td></td> <td></td> <td></td><td></td>
          </tr>
          <tr><td></td> <td></td> <td></td> <td></td> <td></td><td></td>
          </tr>
          <tr><td></td> <td></td> <td></td> <td></td> <td></td> <td></td>
          </tr>
          <tr><td></td> <td></td> <td></td> <td></td> <td></td><td></td>
          </tr>
          <tr><td></td> <td></td> <td></td> <td></td> <td></td><td></td>
          </tr>
          <tr><td></td> <td></td> <td></td> <td></td> <td></td><td></td>
          </tr>
          <tr><td></td> <td></td> <td></td> <td></td> <td></td> <td></td>
          </tr>
          <tr><td></td> <td></td> <td></td> <td></td> <td></td><td></td>
          </tr>
          <tr><td></td> <td></td> <td></td> <td></td> <td></td><td></td>
          </tr>
          
          
          
          
          {/* <tr><td>Tarkan ile Çay</td> <td>Saat: 12:00</td><td>Saat: 12:00</td></tr> */}
          {/* <tr><td>Tarkan ile Çay</td> <td>Saat: 12:00</td><td>Saat: 12:00</td></tr> */}

        </tbody>
      </Table>
    </div>
  );
};

export default ToreverseList;
