import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link } from 'react-router-dom';
import axios from "axios";
import { withRouter, useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';

import AdminMenu from './admin-menu';
// import favicon from '../favicon.png'
import favicon from '../favicon.jpeg';
import { LOGOUT_URL_PROD } from './api-calls/requests';

// const LOGOUT_URL_PROD = 'http://127.0.0.1:8000/api/logout';  //For developement
// const LOGOUT_URL_PROD = 'https://api.buradago.com/public/api/logout';  //For production

const ISLOGGEDIN_URL_PROD = 'http://127.0.0.1:8000/api/isloggedin';  //For developement

// const ISLOGGEDIN_URL_PROD = 'https://api.buradago.com/public/api/isloggedin';  //For production


function Header() {

  
  const navigateThere = useNavigate()
  const [isLoggedin, setIsLoggedin] = useState('false');
  const [userType, setUserType] = useState('fffff');


  //Check if loggedin


  useEffect(()=>{
    let loginVal = JSON.parse(localStorage.getItem('isLoggedIn'));

  if(loginVal==='true'){
    setIsLoggedin('true')
    let user_type = JSON.parse(localStorage.getItem('user_type'));
    
    setUserType(user_type);
    // console.log("User type is: ",user_type);

  } else{navigateThere('/giris')}

  },[]);
 

  




  const handleLogout  = async (e)=> {

    try {
      const response = await axios.get(LOGOUT_URL_PROD,
          // JSON.stringify({ email,password }),
          {
              headers: { 'Content-Type': 'application/json',
            'Accept':'application/json' },
              withCredentials: true
          }
      );
      // console.log(response?.data);
      if (response?.status==200){
        setIsLoggedin('false');
        localStorage.setItem('isLoggedIn', JSON.stringify('false'));
        localStorage.setItem('first_name', JSON.stringify(''));
        localStorage.setItem('last_name', JSON.stringify(''));
        localStorage.setItem('phone', JSON.stringify(''));
        localStorage.setItem('user_type', JSON.stringify(''));
        navigateThere('/giris')}
      // console.log(response?.data.status);
      // if (response?.data.status=='success'){navigateThere('/giris')}
      
      // console.log(response?.accessToken);
      // console.log(JSON.stringify(response))
      // setSuccess(true);
      // setErrorMessage('')
      //clear state and controlled inputs
      //need value attrib on inputs for this
      // setName('');
      // setPwd('');
      // setMatchPwd('');
  } catch (err) {
      if (!err?.response) {
        //CALL THE FOLLOWING LINES ONLY IF ERROR CODE INDICATES THAT USER
        //IS LOGGED OUT ALREADY
        setIsLoggedin('false');
        localStorage.setItem('isLoggedIn', JSON.stringify('false'));
        localStorage.setItem('first_name', JSON.stringify(''));
          localStorage.setItem('last_name', JSON.stringify(''));
          localStorage.setItem('phone', JSON.stringify(''));
          localStorage.setItem('user_type', JSON.stringify(''));
          navigateThere('/giris')
        console.log('There was an error');
        // setErrorMessage('No Server Response');
      } else if (err.response?.status === 409) {
        console.log('There was an error');
        // setErrorMessage('Username Taken');
      } else {
        // console.log(JSON.stringify({ username,password}));
        // setErrorMessage('Login Failed')
      }
      // errRef.current.focus();
  }



  }

  if (JSON.parse(localStorage.getItem('isLoggedIn')) !=='true') {
 return (<></>)
    // navigateThere('/giris')
  } else {   
  
  
  return (
    <>
      {['sm', ].map((expand) => (
        <Navbar key={expand} expand={expand} fixed='top' bg={'dark'} className="bg-body-tertiary mb-4">
          <Container fluid>
            <Navbar.Brand Link as={Link} to="/"><img src={favicon} width="70" height="40"></img> </Navbar.Brand>

            {/* <Navbar.Brand href="#"> <img src={favicon} width="70" height="40"></img> </Navbar.Brand> */}
            
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  Buradago
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  
                  <Nav.Link as={Link} to="/">Anasayfa</Nav.Link>
                  
                  <AdminMenu expand/>
                  
                  <NavDropdown
                    title="Hesabim"
                    id={`offcanvasNavbarDropdown-expand-${expand}`}
                  >
                    <NavDropdown.Item Link as={Link} to="/user-admin">Profilim</NavDropdown.Item>
                   
                    {/* <NavDropdown.Divider /> */}
                    <NavDropdown.Item onClick={handleLogout}>
                      Çıkış
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>
                <Form className="d-flex">
                  <Form.Control
                    type="search"
                    placeholder="Etkinlik Ara"
                    className="me-2"
                    aria-label="Search"
                  />
                  <Button variant="outline-success">Ara</Button>
                </Form>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
}
}

export default Header;