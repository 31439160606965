import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import '../App.css';
import axios from 'axios';
import { ALL_EVENTS_URL, createQrs, sendQrs } from './api-calls/requests';
import { useNavigate } from 'react-router-dom';

// const ALL_EVENTS_URL = 'http://127.0.0.1:8000/api/all-events';  //For dev
// const ALL_EVENTS_URL = 'https://api.buradago.com/public/api/all-events';  //For p

const AdminEtkinlikTable = ({ data }) => {

  const [events, setEvents] = useState([]);

  const navigateThere = useNavigate();

const getAllEvents = async ()=>{

  try {

    const response = await axios.get(ALL_EVENTS_URL,
        // JSON.stringify({ email,password }),
        {
            headers: { 'Content-Type': 'application/json',
          'Accept':'application/json',
          // 'Access-Control-Allow-Credentials':true
         },
            withCredentials: true
        }
    );

    if (response?.data){
      setEvents(response?.data)
    }

} catch (err) {
    if (!err?.response) {
      console.log('There was an error');
    } else if (err.response?.status === 409) {
      console.log('There was an error');
    } else {
      // console.log(JSON.stringify({ username,password}));
    }
    // errRef.current.focus();
}
}


useEffect(
  ()=>{getAllEvents();},[]
);


  return (
    <Table striped bordered hover className='table'>
      <thead>
        <tr>
          <th>Kod</th>
          <th>Etkinlik Adı</th>
          <th>Fenomen Adı</th>
          <th>Etkinlik Tarihi</th>
          <th>Etkinlik Mekani</th>
          <th>Eylemler</th>
        </tr>
      </thead>
      <tbody>
        {events.map((item, index) => (
          <tr key={index}>
            <td>{item.id}</td>
            <td>{item.etkinlik_adi}</td>
            <td>{item.fenomen_id}</td>
            <td>{item.etkinlik_tarihi}</td>
            <td>{item.etkinlik_mekan}</td>
            <td className='text-sm space-x-2'>
              <span
              onClick={() => { navigateThere('/etkinlik-detay',{state:{event_id:item.id}}) }}
               className='cursor-pointer px-[1rem] rounded-md bg-green-300'>
                Detailar
              </span>

              {/* QRcode creation button */}
              {item.qrcode_creation==='in_progress'?
              <span className='bg-info rounded-5 p-1 me-2 '>
                Karekodlari oluşturuluyor
              </span>
                    ://second condition
                    item.qrcode_creation==='created'?
                    <span className='bg-info rounded-5 p-1 me-2 '>
                       Karekodlari hazır
                    </span>
                          ://3rd or default condition (anything else)
                          <span className='bg-info rounded-5 cursor-pointer p-1 me-2 '
                          onClick={()=>{createQrs({'event_id':item.id})}}
                          >
                            Kare kodu Oluştur
                          </span>
              }

              {/* QRcode mail sending button */}
              {item.qrcode_creation==='created'?
              <span className='bg-primary-subtle cursor-pointer p-1 rounded-5'
              onClick={()=>{sendQrs({'event_id':item.id})}}
              >
                Kare kodu Gönder
              </span>
                      :
                      <></>
              }
              </td>
            
          </tr>
        ))}
        <tr><td></td> <td></td> <td></td> <td></td> <td></td><td></td>
        </tr>
        <tr><td></td> <td></td> <td></td> <td></td> <td></td> <td></td>
        </tr>
        <tr><td></td> <td></td> <td></td> <td></td> <td></td><td></td>
        </tr>
        <tr><td></td> <td></td> <td></td> <td></td> <td></td><td></td>
        </tr>
        <tr><td></td> <td></td> <td></td> <td></td> <td></td><td></td>
        </tr>
        <tr><td></td> <td></td> <td></td> <td></td> <td></td> <td></td>
        </tr>
        <tr><td></td> <td></td> <td></td> <td></td> <td></td><td></td>
        </tr>
        <tr><td></td> <td></td> <td></td> <td></td> <td></td><td></td>
        </tr>
        
         
        
        
        {/* <tr><td>Tarkan ile Çay</td> <td>Saat: 12:00</td><td>Saat: 12:00</td></tr> */}
        {/* <tr><td>Tarkan ile Çay</td> <td>Saat: 12:00</td><td>Saat: 12:00</td></tr> */}

      </tbody>
    </Table>
  );
};

export default AdminEtkinlikTable;
