
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link } from 'react-router-dom';

const AdminMenu = (expand)=>{

    return(
        <>
        <NavDropdown
            title="Oluştur"
            id={`offcanvasNavbarDropdown-expand-${expand}`}
          >
            <NavDropdown.Item Link as={Link} to="etkinlik-olustur-page">Etkinlik Oluştur</NavDropdown.Item>                    
            <NavDropdown.Item Link as={Link} to="">Ürün Ekle</NavDropdown.Item>
            <NavDropdown.Item Link as={Link} to="">Diğer</NavDropdown.Item>
                   
                    
        </NavDropdown>

        <NavDropdown
                    title="Etkinlikler"
                    id={`offcanvasNavbarDropdown-expand-${expand}`}
                  >
                    <NavDropdown.Item Link as={Link} to="">Tüm Etkinlikler</NavDropdown.Item>
                    <NavDropdown.Item Link as={Link} to="etkinlik-kisisi-ekleme">Etkinlik Kisi Ekle</NavDropdown.Item>
                    <NavDropdown.Item Link as={Link} to="etkinlik-yetkili-kisisi-ekleme">Etkinlik yetkili kişi Ekle</NavDropdown.Item>
                   
                    
        </NavDropdown>

        

        <NavDropdown
                    title="Mesajlar"
                    id={`offcanvasNavbarDropdown-expand-${expand}`}
                  >
                    <NavDropdown.Item Link as={Link} to="">Gelen Mesaglar</NavDropdown.Item>
                    <NavDropdown.Item Link as={Link} to="">Mesag Yaz</NavDropdown.Item>
                   
                   
        </NavDropdown>

        <NavDropdown
                    title="Yorumlar"
                    id={`offcanvasNavbarDropdown-expand-${expand}`}
                  >
                    <NavDropdown.Item Link as={Link} to="">Gelen Yorumlar</NavDropdown.Item>
                   
                   
        </NavDropdown>

        <NavDropdown
                    title="Cari"
                    id={`offcanvasNavbarDropdown-expand-${expand}`}
                  >
                    <NavDropdown.Item Link as={Link} to="">Kişiler</NavDropdown.Item>
                    <NavDropdown.Item Link as={Link} to="">Fatura İste</NavDropdown.Item>
                    <NavDropdown.Item Link as={Link} to="">İstenen Fatura Listesi</NavDropdown.Item>
                    <NavDropdown.Item Link as={Link} to="uploaded-invoices">Yüklenen Faturalar</NavDropdown.Item>
                    <NavDropdown.Item Link as={Link} to="">Onaylanan Faturalar</NavDropdown.Item>
                    <NavDropdown.Item Link as={Link} to="">Yapilan Ödemeler</NavDropdown.Item>
                    <NavDropdown.Item Link as={Link} to="">Yüklenen Evraklar</NavDropdown.Item>
                    <NavDropdown.Item Link as={Link} to="payment-reversal">Etkinlik İşlemleri</NavDropdown.Item>

                    
        </NavDropdown>
        </>);

        
}

export default AdminMenu;