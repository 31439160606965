import EtkinlikOlusturForm from "../components/etkinlik-olustur";
import "../App.css";
import Footer from "../components/footer";


const EtkinlikOlusturPage = () =>{

    return(
        <div>
        <div className="register">

        <EtkinlikOlusturForm/>
        
        </div>
        <Footer/> 

        </div>
    );
}

export default EtkinlikOlusturPage;