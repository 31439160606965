import { useEffect, useState } from 'react';
import '../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { CContainer,CRow,CCol, CHeaderDivider, CFormLabel  } from '@coreui/react';
import PastEventsTable from '../user-past-event';
import axios from 'axios';
import user_avatar from "../../user_avatar.jpg"
import MyFooter from '../footer';


const USER_INFO_URL = 'http://127.0.0.1:8000/api/user';  //For development
// const USER_INFO_URL = 'https://api.buradago.com/public/api/user';  //For production





const UserAdminPage = ()=>{

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [nick, setNick] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [instagram, setInstagram] = useState('');
    const [dateCreated, setDateCreated] = useState('');

    const fetchUserInfo = async ()=>{


        try {
            const response = await axios.get(USER_INFO_URL,
            // JSON.stringify({ email,password }),
            {
                headers: { 'Content-Type': 'application/json',
                'Accept':'application/json' },
                withCredentials: true
            }
             );
            //  console.log(response)
            //  axios.get(USER_INFO_URL,
            //     // JSON.stringify({ email,password }),
            //     {
            //         headers: { 'Content-Type': 'application/json',
            //         'Accept':'application/json' },
            //         withCredentials: true
            //     }
            //      ).then((response2)=>{

            //     var parsedUser = JSON.parse(response2.data.user)
            //     console.log(parsedUser)
            //     setFirstName(parsedUser.first_name)
            //     setLastName(parsedUser.last_name)
            //     setNick(parsedUser.nick)
            //     setEmail(parsedUser.email)
            //     setPhone(parsedUser.phone)            
            //     setInstagram(parsedUser.instagram)
            //     setDateCreated(parsedUser.created_at)


            //  });

            // console.log("Response before if statement",response?.data)

            // if (response?.data.status=='loggedin'){
            // localStorage.setItem('isLoggedIn', JSON.stringify('true'));
            
            // navigateThere('/')

            //update user info states
            // var parsedUser = JSON.parse(response?.data.user)
            setFirstName(response?.data.first_name)
            setLastName(response?.data.last_name)
            setNick(response?.data.nick)
            setEmail(response?.data.email)
            setPhone(response?.data.phone)            
            setInstagram(response?.data.instagram)
            setDateCreated(response?.data.created_at)
            
            // console.log("PasedUserInfo object",parsedUser)
            // console.log("FirstName state is: ",firstName)


            // }
            //  console.log(response?.data);
    
        } catch (err) {
                if (!err?.response) {
                console.log('There was an error');
                // setErrorMessage('No Server Response');
                      } 

                }
    }

    useEffect(
        ()=>{fetchUserInfo()}
    ,[]);

    return (
        <div>
        <div class="container homebody bootstrap snippets bootdey">
            <div class="panel-body inf-content">
                <div class="row justify-content-around">
                    <div class="col-md-4">
                        <img alt="" style={{ width: '600px' }} title="" class="img-circle img-thumbnail isTooltip" src={user_avatar} data-original-title="Usuario" />
                        <p className='text-danger'>Not: Profil fotonuz hesabınızın onaylanmasıyla otomatik olarak değişecektir</p>
                        <ul title="Ratings" class="list-inline ratings text-center">
                            <li><a href="#"><span class="glyphicon glyphicon-star"></span></a></li>
                            <li><a href="#"><span class="glyphicon glyphicon-star"></span></a></li>
                            <li><a href="#"><span class="glyphicon glyphicon-star"></span></a></li>
                            <li><a href="#"><span class="glyphicon glyphicon-star"></span></a></li>
                            <li><a href="#"><span class="glyphicon glyphicon-star"></span></a></li>
                        </ul>
                    </div>
                    <div class="col-md-6">
                        <strong style={{ color: 'white' }}>Bilgilerim</strong><br />
                        <div class="table-responsive">
                            <table class="table table-user-information " style={{borderRadius:'10px',overflow:'hidden'}}>
                                <tbody>
                                    <tr>
                                        <td>
                                            <strong>
                                                <span class="glyphicon glyphicon-asterisk text-primary"></span>
                                                İnstagram
                                            </strong>
                                        </td>
                                        <td class="text-primary">
                                            {(instagram && instagram)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>
                                                <span class="glyphicon glyphicon-user  text-primary"></span>
                                                Ad
                                            </strong>
                                        </td>
                                        <td class="text-primary">
                                        {(firstName && firstName)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>
                                                <span class="glyphicon glyphicon-cloud text-primary"></span>
                                                Soyad
                                            </strong>
                                        </td>
                                        <td class="text-primary">
                                        {(lastName && lastName)}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <strong>
                                                <span class="glyphicon glyphicon-bookmark text-primary"></span>
                                                Nick
                                            </strong>
                                        </td>
                                        <td class="text-primary">
                                        {(nick && nick)}
                                        </td>
                                    </tr>


                                    <tr>
                                        <td>
                                            <strong>
                                                <span class="glyphicon glyphicon-eye-open text-primary"></span>
                                                Mail Adresi
                                            </strong>
                                        </td>
                                        <td class="text-primary">
                                        {(email && email)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>
                                                <span class="glyphicon glyphicon-envelope text-primary"></span>
                                                Tel No
                                            </strong>
                                        </td>
                                        <td class="text-primary">
                                        {(phone && phone)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>
                                                <span class="glyphicon glyphicon-calendar text-primary"></span>
                                                Üyelik Tarihi
                                            </strong>
                                        </td>
                                        <td class="text-primary">
                                        {(dateCreated && dateCreated)}
                                        </td>
                                    </tr>
                                    {/* <tr>
                                        <td>
                                            <strong>
                                                <span class="glyphicon glyphicon-calendar text-primary"></span>
                                                Modified
                                            </strong>
                                        </td>
                                        <td class="text-primary">
                                            20 jul 20014 20:00:00
                                        </td>
                                    </tr> */}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <MyFooter/>
        </div>
    );
}

export default UserAdminPage;