import React, { useEffect, useState } from 'react'
import { ASSIGN_GATEMAN_URL, GET_GATEMAN_CREATION_DATA_URL, GET_GATEMAN_INIT_DATA_URL, generalGetMethod, generalPostMethod, generalPostNotFormMethod } from './api-calls/requests';
import { useNavigate } from 'react-router-dom';

const AddEventGateman = () => {
    const [formData,setformData] = useState( {
        event_id:"",
        gateman_id:"",
        gateman_status:""
    });

const [events, setevents] = useState([]);
const [users, setusers] = useState([]);

const navigateThere = useNavigate();

const handleSelect = (target) => { 
    setformData({...formData,[target.name]:target.value});
 }

const handlesubmit =(e) => { 
    e.preventDefault();
    generalPostNotFormMethod(ASSIGN_GATEMAN_URL,formData).then((response) => { 
        if (response.status===200) {
            alert("Yetkili kşı işlemi tamamlandı")
            navigateThere("/")
        }else{
            alert("Başarısız yetkili kşı işlemi")
        }
     })
 }

 useEffect(() => { 
    generalGetMethod(GET_GATEMAN_INIT_DATA_URL).then((response) => { 
        if (response.status===200) {
            setevents(response.data.events)
            setusers(response.data.users)
            // console.log('events',response.data.users);
        } else {
            
        }
     })
  },[]);

  return (
    <div className="mt-[5rem] w-full" >
        <div className='w-full text-[2rem] pb-3 text-white text-center'>
            Etkinlik yetkili kişisi Atanmasi
        </div>
        <div className="flex justify-center">
            <form onSubmit={handlesubmit}
            className=" bg-gray-400 lg:w-[40%] w-[90%] lg:h-[60%]\
            h-[60%] p-3 rounded-md">
                <div className='w-full text-start mb-3'>
                    <p className='mb-0'>Etkinliği seç</p>
                    <select name="event_id" id=""
                    required
                    onChange={(event) => { handleSelect(event.target) }}
                    value={formData.event_id}
                    className='w-full h-[2.4rem] rounded-md pl-1'>
                    <option  value='' >Seç</option>
                    {/* //here map through events */}
                    {events&&events.map(event =>(
                        <option key={event.id} value={event.id}>{'#'+event.id+' '+event.etkinlik_adi}</option>
                    ))}
                    </select>
                </div>

                <div className='w-full text-start mb-3'>
                    <p className='m-0'>Yetkili kişi seç</p>
                    <select name="gateman_id" id=""
                    required
                    onChange={(event) => { handleSelect(event.target) }}
                    value={formData.gateman_id}
                    className="w-full h-[2.4rem] rounded-md pl-1"
                    >
                    <option  value='' >Seç</option>

                    {users&&users.map(user =>(
                        <option key={users.id} value={user.id}>{'#'+user.id+' '+user.email+' '+user.first_name+' '+user.last_name}</option>
                    ))}
                    </select>
                </div>

                <div className='w-full text-start'>
                    <p className='mb-0'>Yetki Durumu</p>
                    <select name="gateman_status" id=""
                    required
                    onChange={(event) => { handleSelect(event.target) }}
                    value={formData.gateman_status}
                    className='w-full h-[2.4rem] rounded-md pl-1'>
                        <option  value='' >Durumu seç</option>
                        <option value='1' >Aktiflensin</option>
                        <option value='0' >Pasiflensin</option>
                    </select>
                </div>

                <button type='submit'
                className='bg-blue-400 h-[2rem] w-[30%] rounded-md px-2 mt-2\
                my-5'
                >                Tamamla
                </button>
            </form>

        </div>
    </div>
  )
}

export default AddEventGateman