import React, { useEffect, useState } from 'react'
import { closeTicketView } from "../item-imports";
import IndividualAddress from './individual-address';
import OrganizationalAddress from './organizational-address';
import { generalGetMethod, GET_BILLING_ADDRESS } from '../api-calls/requests';

const ViewBillingAddress = ({addressId,setshowBillingPopup}) => {
  
  const [billAddress, setbillAddress] = useState([]);
   //address types
   const ind_add_type = 'individual';
   const org_add_type = 'organizational';

useEffect(() => {
  generalGetMethod(GET_BILLING_ADDRESS+addressId).then((response) => {
    if (response.status===200 && response.data.address) {
      setbillAddress(response.data.address);
      
    }
  })
  
  }, [])


  return (
    <div className='w-full h-[60%] absolute mt-[2rem]  '>
      <div
       className="text-white z-30 w-full   flex justify-center">
          
          <div className=" w-full lg:w-[25rem] h-[25rem] rounded-md bg-gray-200">
            {/* close button */}
            <div className="w-full flex justify-end">
              <img
              className="cursor-pointer w-[2rem] p-1 m-1"
              onClick={() => { setshowBillingPopup(false) }}
              src={closeTicketView} alt="" />
            </div>

            {/* billing address */}
            {billAddress.length>0&&
            <div className="w-full h-full">
              {billAddress[0].address_type===ind_add_type?
                <IndividualAddress
                    address={billAddress[0]} />
                :
                <OrganizationalAddress
                    address={billAddress[0]}/>
              }
            </div>
            }
          </div>

        </div>
    </div>
  )
}

export default ViewBillingAddress